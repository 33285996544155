'use client';

import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';
type PopUpProps = {
  visible: boolean;
  onClose: () => void;
  children: ReactNode;
};
export const PopUp = (props: PopUpProps) => {
  const {
    visible,
    onClose,
    children
  } = props;
  return <Transition appear show={visible} as={Fragment} data-sentry-element="Transition" data-sentry-component="PopUp" data-sentry-source-file="PopUp.tsx">
      <Dialog as="div" open={visible} onClose={onClose} data-sentry-element="Dialog" data-sentry-source-file="PopUp.tsx">
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="unknown" data-sentry-source-file="PopUp.tsx">
          <div className="fixed inset-0" />
        </Transition.Child>
        <div className="fixed inset-0 z-[999] bg-[black]/60 px-4">
          <div className="flex min-h-screen items-start justify-center px-4">
            <Dialog.Panel className="panel my-8 w-full max-w-lg overflow-hidden  rounded-lg border-0 p-0 text-black dark:text-white-dark" data-sentry-element="unknown" data-sentry-source-file="PopUp.tsx">
              {children}
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>;
};