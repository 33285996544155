'use client';

import { IconX } from '@tabler/icons-react';
import { Button, ButtonVariantType } from './form';
import { PopUp } from './PopUp';
type MessagePopUpProps = {
  visible: boolean;
  title: string;
  description: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  cancelButtonVariant?: ButtonVariantType;
  confirmButtonVariant?: ButtonVariantType;
  onClose: () => void;
  onConfirm?: () => void;
};
export const MessagePopUp = (props: MessagePopUpProps) => {
  const {
    visible,
    title,
    description,
    cancelButtonText = 'Cancel',
    confirmButtonText = 'Confirm',
    cancelButtonVariant = 'outline',
    confirmButtonVariant = 'danger',
    onClose,
    onConfirm
  } = props;
  return <PopUp visible={visible} onClose={onClose} data-sentry-element="PopUp" data-sentry-component="MessagePopUp" data-sentry-source-file="MessagePopup.tsx">
      <div className="flex items-center justify-between bg-[#fbfbfb] px-5 py-3 dark:bg-[#121c2c]">
        <h5 className="text-lg font-bold">{title}</h5>
        <button type="button" onClick={onClose} className="text-white-dark hover:text-dark">
          <IconX data-sentry-element="IconX" data-sentry-source-file="MessagePopup.tsx" />
        </button>
      </div>
      <div className="p-5">
        <p>{description}</p>
        <div className="mt-8 flex items-center justify-end">
          <Button variant={cancelButtonVariant} onClick={onClose} data-sentry-element="Button" data-sentry-source-file="MessagePopup.tsx">
            {cancelButtonText}
          </Button>
          <Button className="ml-5" variant={confirmButtonVariant} onClick={onConfirm} data-sentry-element="Button" data-sentry-source-file="MessagePopup.tsx">
            {confirmButtonText}
          </Button>
        </div>
      </div>
    </PopUp>;
};